.rating {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.rating h1 {
  font-size: 32px;
  color: var(--dr1);
  margin: 0 0 5px;
}
.rating .emptyMsg {
  color: var(--dr1);
}
.rating form,
.reviews form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}
.ratingDiv {
  display: grid;
  grid-template-columns: auto 230px;
}
.stars {
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
}
.stars h2 {
  color: var(--dr2);
  margin: 0 10px 0 0;
  font-weight: 400;
}
.average {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.AvrgStars {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: solid 2px var(--dr1);
  padding: 10px 0 20px;
}
.AvrgStars SVG {
  margin: 0 10px;
}
.AvrgStars h2 {
  color: var(--dr2);
  font-weight: 600;
  font-size: 32px;
  margin: 0;
}
.count {
  font-weight: 300 !important;
}

.reviews {
  background-color: var(--dr1);
  color: var(--lt2);
  padding: 20px;
}
.reviews h1 {
  font-size: 32px;
  text-align: center;
}
.reviews div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-x: auto;
  scrollbar-color: var(--lt2) var(--md);
  margin-left: auto;
  margin-right: auto;
  scroll-snap-type: x mandatory;
  align-items: center;
  max-width: 1000px;
}
.reviews div::-webkit-scrollbar {
  background-color: var(--md);
  border-radius: 6px;
  height: 12px;
}
.reviews div::-webkit-scrollbar-button {
  display: none;
}
.reviews div::-webkit-scrollbar-thumb {
  background-color: var(--lt2);
  border-radius: 6px;
  height: 12px;
}
.reviews div p {
  padding: 10px;
  max-width: 300px;
  border-top: solid 1px var(--md);
  margin: 0 10px 10px;
  text-align: justify;
  scroll-snap-align: start;
}
.greeting {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 20px 40px;
}
.greeting Img {
  border-radius: 10px;
  background-color: ivory;
  border-radius: 10px;
  box-shadow: 2px 2px var(--dr2);
  margin-right: 10px;
  height: 300px;
}
.greetingDiv {
  margin: 10px;
  color: var(--dr1);
  max-width: 400px;
}
.greeting h2 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
.greeting p {
  text-align: justify;
}

.emptyMsg {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.emptyMsg h2 {
  font-weight: 400;
}

.services {
  fill: white;
  color: white;
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-wrap: wrap;
  background-color: var(--dr2);
}
.services div {
  margin: 10px 30px;
}
.itreat {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.services h2 {
  margin: 0 0 20px;
  padding-bottom: 5px;
  border-bottom: solid 1px var(--md);
}
.itreat h2 {
  grid-row: 1/2;
  grid-column: 1/3;
  text-align: center;
}
.itreat h3 {
  margin: 5px 0;
}

.itreat div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 10px;
}

.treatment h2 {
  text-align: center;
}
.treatment ul {
  margin-left: 20px;
}

/* Phone */
@media screen and (max-width: 600px) {
  .greeting {
    padding: 20px;
  }
  .greeting img {
    width: 100%;
    height: auto;
    margin: 0;
    max-width: 400px;
  }
  .greeting div {
    margin: 10px 0 0;
  }
  .ratingDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ratingDiv p {
    text-align: center;
  }
}
