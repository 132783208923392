:root {
  --dr1: #293847;
  --dr2: #14466d;
  --md: #628ab2;
  --lt2: #9ecaea;
}
* {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
/*{font-family: 'PT Serif', serif;}*/

p,
ul,
h1,
h2,
h3 {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
ul {
  padding-inline-start: 0;
}

a:hover {
  transition: 500ms ease;
}
a {
  color: unset;
  text-decoration: unset;
}

body {
  background-color: var(--lt2);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  position: absolute;
  width: 100%;
  left: 0;
  transition: 500ms ease;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#__next {
  width: 100%;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

hr {
  border-width: 0 0 1.5px;
  border-color: var(--md);
  border-style: solid;
  margin-block-start: 10px;
  margin-block-end: 10px;
}

button {
  border-radius: 5px;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  background-color: var(--md);
  color: white;
  text-transform: uppercase;
  box-shadow: 2px 2px var(--dr2);
  transform: translate(-2px, -2px);
}
button:hover {
  transition: 500ms ease;
}

button:hover {
  box-shadow: 3px 3px var(--dr2);
  transform: translate(-3px, -3px);
}
button:focus {
  box-shadow: 0 0 var(--dr2);
  transform: translate(0, 0);
  background-color: var(--dr2);
}

section {
  width: 100%;
}

#content {
  min-height: calc(100vh - 128px);
}

header {
  background-color: var(--dr2);
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HeaderLink {
  margin-right: 10px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
}
.HeaderLink:hover {
  color: var(--lt2);
}

/* Footer */
footer {
  width: 100%;
  background-color: var(--dr1);
  color: var(--lt2);
  fill: var(--lt2);
  display: flex;
  justify-content: center;
  padding: 10px;
  flex-wrap: wrap;
}
footer a,
footer div {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
footer a {
  fill: var(--lt2);
}
footer a:hover {
  color: white;
  fill: white;
}
.wicn {
  margin-left: 5px;
}
/* Mobile*/
@media screen and (max-width: 900px) {
  .mobileNav {
    display: flex;
  }
  footer {
    display: none;
  }
  #content {
    min-height: auto;
  }
}
